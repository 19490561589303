import React, { useState } from "react"
import data from "../data/bible.json"
import Select from "react-select"
import "./bible.scss"

import _ from "lodash"

const BibleReference = () => {
  const [chapters, setChapters] = useState("")
  const [verses, setVerses] = useState("")
  const [selectedVerse, setSelectedVerse] = useState("")
  const [selectedVerseCopy, setSelectedVerseCopy] = useState("")
  const [font, setFont] = useState(20)
  const [fontCopy, setFontCopy] = useState(20)

  const [bookLabel, setBookLabel] = useState("")
  const [bookLabelCopy, setBookLabelCopy] = useState("")
  const [chapterNum, setChapterNum] = useState("")
  const [chapterNumCopy, setChapterNumCopy] = useState("")
  const [verseNum, setVerseNum] = useState("")
  const [verseNumCopy, setVerseNumCopy] = useState("")

  const bookNames = [
    "Genesis - ఆదికాండము",
    "Exodus - నిర్గమకాండము",
    "Leviticus - లేవీయకాండము",
    "Numbers - సంఖ్యాకాండము",
    "Deuteronomy - ద్వితీయోపదేశకాండము",
    "Joshua - యెహోషువ",
    "Judges - న్యాయాధిపతులు",
    "Ruth - రూతు",
    "Samuel I- 1 సమూయేలు",
    "Samuel II - 2 సమూయేలు",
    "Kings I - 1 రాజులు",
    "Kings II - 2 రాజులు",
    "Chronicles I - 1 దినవృత్తాంతములు",
    "Chronicles II - 2 దినవృత్తాంతములు",
    "Ezra - ఎజ్రా",
    "Nehemiah - నెహెమ్యా",
    "Esther - ఎస్తేరు",
    "Job - యోబు",
    "Psalms - కీర్తనల గ్రంథము",
    "Proverbs - సామెతలు",
    "Ecclesiastes - ప్రసంగి",
    "Song of Solomon - పరమగీతము",
    "Isaiah - యెషయా",
    "Jeremiah - యిర్మియా",
    "Lamentations - విలాపవాక్యములు",
    "Ezekiel - యెహెఙ్కేలు",
    "Daniel - దానియేలు",
    "Hosea - హోషేయ",
    "Joel - యోవేలు",
    "Amos - ఆమోసు",
    "Obadiah - ఓబద్యా",
    "Jonah - యోనా",
    "Micah - మీకా",
    "Nahum - నహూము",
    "Habakkuk - హబక్కూకు",
    "Zephaniah - జెఫన్యా",
    "Haggai - హగ్గయి",
    "Zechariah - జెకర్యా",
    "Malachi - మలాకీ",
    "Matthew - మత్తయి సువార్త",
    "Mark - మార్కు సువార్త",
    "Luke - లూకా సువార్త",
    "John - యోహాను సువార్త",
    "Acts - అపొ. కార్యములు",
    "Romans - రోమీయులకు",
    "Corinthians I - 1 కొరింథీయులకు",
    "Corinthians II - 2 కొరింథీయులకు",
    "Galatians - గలతీయులకు",
    "Ephesians - ఎఫెసీయులకు",
    "Philippians - ఫిలిప్పీయులకు",
    "Colossians - కొలస్సయులకు",
    "Thessalonians I - 1 థెస్సలొనీకయులకు",
    "Thessalonians II - 2 థెస్సలొనీకయులకు",
    "Timothy I - 1 తిమోతికి",
    "Timothy II - 2 తిమోతికి",
    "Titus - తీతుకు",
    "Philemon - ఫిలేమోనుకు",
    "Hebrews - హెబ్రీయులకు",
    "James - యాకోబు",
    "Peter I - 1 పేతురు",
    "Peter II - 2 పేతురు",
    "John I - 1 యోహాను",
    "John II - 2 యోహాను",
    "John III - 3 యోహాను",
    "Judah - యూదా",
    "Revelation - ప్రకటన గ్రంథము",
  ]

  const renderbooks = () => {
    const options = []
    data.Book.map((book, i) => {
      const bookobj = { value: book, label: bookNames[i] }
      options.push(bookobj)
    })
    return options
  }
  const renderchapters = () => {
    const options = []
    chapters.Chapter.map((chapter, i) => {
      const bookobj = { value: chapter, label: i + 1 }
      options.push(bookobj)
    })
    return options
  }

  const renderVerses = () => {
    const options = []
    verses.Verse.map((verse, i) => {
      const bookobj = { value: verse, label: i + 1 }
      options.push(bookobj)
    })
    return options
  }

  // console.log(data.Book)
  return (
    <div className="readBible">
      <div className="selection">
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          onChange={e => {
            setChapters(e.value)
            setBookLabel(e.label)
          }}
          options={renderbooks()}
        />
        {chapters ? (
          <Select
            className="react-select-container numbers"
            classNamePrefix="react-select numbers"
            onChange={e => {
              setVerses(e.value)
              setChapterNum(e.label)
            }}
            options={renderchapters()}
          />
        ) : null}
        {verses ? (
          <Select
            className="react-select-container numbers"
            classNamePrefix="react-select numbers"
            onChange={e => {
              setSelectedVerse(e.value)
              setVerseNum(e.label)
            }}
            options={renderVerses()}
          />
        ) : null}
        <input
          type="range"
          min="20"
          max="50"
          value={font}
          onChange={e => setFont(e.target.value)}
        />
        {console.log(font)}
      </div>
      {selectedVerse ? (
        <div className="view scale">
          <div className="label">
            <span className="book">{bookLabel.split("-")[1]}</span>
            <span className="verse">
              {chapterNum} : {verseNum}
            </span>
          </div>
          <div className="verse" id="idpar">
            <span style={{ fontSize: font + "px" }}>{selectedVerse.Verse}</span>
          </div>
          <button
            onClick={() => {
              setSelectedVerseCopy(selectedVerse)
              setVerseNumCopy(verseNum)
              setBookLabelCopy(bookLabel)
              setChapterNumCopy(chapterNum)
              setFontCopy(font)
            }}
          >
            Go
          </button>
        </div>
      ) : null}

      {selectedVerseCopy ? (
        <div className="view">
          <div className="label">
            <span className="book">{bookLabelCopy.split("-")[1]}</span>
            <span className="verse">
              {chapterNumCopy} : {verseNumCopy}
            </span>
          </div>
          <div className="verse" id="idpar">
            <span style={{ fontSize: fontCopy + "px" }}>
              {selectedVerseCopy.Verse}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default BibleReference
